<template>
  <div class="page page--home">
    <section class="section" id="sec-1" data-sec="1">
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-6 col-xl-6">
            <div class="wrapper d-flex flex-column justify-content-center">
              <h1 class="title fs-32 fs-sm-50 fs-md-42 fw-600 lh-13 ta-center ta-md-left mb-3 mb-md-4 pb-md-2"
                  :class="[{'fs-xl-58': ($i18n.locale !== 'ru' && $i18n.locale !== 'es')},
                           {'fs-xl-52': $i18n.locale === 'ru' || $i18n.locale === 'es'}]">
                {{ cc.sec1.title }}
              </h1>
              <p class="text tc-silver fs-14 fs-sm-20 fw-400 lh-20 lh-md-17 ta-center ta-md-left mb-5 mb-md-4 mb-md-5">
                {{ cc.sec1.text[0] }}<br class="d-none d-md-block"/>{{ cc.sec1.text[1] }}
              </p>
              <div class="d-flex justify-content-center justify-content-md-start mb-5 mb-md-0">
                <src-external to="reg" class="btn btn-3-blue btn-w-140 btn-w-lg-200 btn-h-52 btn-h-lg-66 me-3 me-md-4">
                  <span>{{ cc.sec1.btn[0].text }}</span>
                </src-external>
              </div>
              <div class="d-none d-lg-flex align-items-center" style="display: none !important;">
                <div class="d-flex align-items-center">
                  <div class="ico ico--circle ico--circle-gray me-3">
                    <img src="/img/icons/ico-apple.svg" alt="Apple Pay">
                  </div>
                  <div class="ico ico--circle ico--circle-gray me-3 me-md-4">
                    <img src="/img/icons/ico-google.svg" alt="Google Pay">
                  </div>
                </div>
                <p class="text tc-silver fw-300">
                  {{ cc.sec1.text[2] }}<br/>{{ cc.sec1.text[3] }}
                </p>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 col-xl-5 offset-xl-1">
            <widget-crypto/>
          </div>
        </div>
      </div>
    </section>
    <section class="section" id="sec-2" data-sec="2">
      <div class="container">
        <div class="row flex-column-reverse flex-lg-row">
          <div class="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-0">
            <widget-calculator/>
          </div>
          <div class="col-12 col-lg-6 ps-lg-5 mb-5 mb-lg-0">
            <div class="wrapper d-flex flex-column justify-content-center">
              <h2 class="title fs-32 fs-sm-50 fs-md-42 fs-xl-54 fw-600 lh-13 ta-center ta-lg-left mb-3 mb-md-4">
                {{ cc.sec2.title }}
              </h2>
              <p class="text tc-silver fs-14 fs-sm-20 fw-400 lh-20 lh-md-17 mb-1 mb-lg-2 ta-center ta-lg-left">{{ cc.sec2.text }}</p>
              <p class="text tc-red fs-12 fs-sm-12 fw-400 lh-20 lh-md-17 mb-4 mb-lg-5 ta-center ta-lg-left">{{ cc.sec2.warning }}</p>
              <src-external to="log" class="link link--arrow link--td-none mx-auto mx-lg-0">
                <span class="link fs-16 fs-md-18 fs-xl-20 tc-blue">
                  {{ cc.sec2.btn.text }}
                </span>
                <i class="ico ico--ui-arrow">
                  <img src="/img/icons/ui/ico-any-arrow-right-blue.svg" alt="arrow">
                </i>
              </src-external>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section" id="sec-additional-1" data-sec="sec-additional-1">
      <div class="container">
        <div class="row">
          <div class="col-12 mb-4">
            <div class="block block--bab">
              <div class="row">
                <div class="col-12 col-md-8 col-lg-8 col-xl-8">
                  <div class="img img--bab mb-4 d-md-flex align-items-end">
                    <img src="/img/logo/logo-bab.svg" alt="BidAskBit" />
                    <p class="text fs-20 fw-500 mt-1 ms-5 ps-1 mt-md-0 ms-md-3 ps-md-0" style="color: #AAC8CF;">{{ cc.secad1.text }}</p>
                  </div>
                  <h2 class="title fs-24 fs-xl-26 fw-600 lh-15 pe-xl-1 mb-5 pb-4 mb-md-4 mb-lg-0 pb-md-0">{{ cc.secad1.title }}</h2>
                </div>
                <div class="col-12 col-lg-3 col-xl-3 offset-lg-1">
                  <div class="wrapper d-flex align-items-center justify-content-start justify-content-lg-end">
                    <router-link :to="`/${$i18n.locale}` + cc.secad1.btn.to" class="btn btn-3-blue btn-w-200 btn-h-66">
                      <span>{{ cc.secad1.btn.text }}</span>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="block block--bab-3">
              <div class="row">
                <div class="col-12 col-md-8 col-lg-8 col-xl-8">
                  <div class="img img--bab mb-4 d-md-flex align-items-end">
                    <!-- <img src="/img/logo/logo-bab.svg" alt="BidAskBit" /> -->
                    <p class="text fs-20 fw-500 mt-1 ps-1 mt-md-0 ps-md-0" style="color: #AAC8CF;">{{ cc.secad2.text }}</p>
                  </div>
                  <h2 class="title fs-24 fs-xl-30 fw-600 lh-15 pe-xl-1 mb-5 pb-4 mb-md-4 mb-lg-0 pb-md-0">{{ cc.secad2.title }}</h2>
                </div>
                <div class="col-12 col-lg-3 col-xl-3 offset-lg-1">
                  <div class="img d-flex align-items-center justify-content-center justify-content-lg-end">
                    <!-- <router-link :to="`/${$i18n.locale}` + cc.secad1.btn.to" class="btn btn-3-blue btn-w-200 btn-h-66">
                      <span>{{ cc.secad1.btn.text }}</span>
                    </router-link> -->
                    <a href="https://rallyekrumlov.cz/" target="_blank">

                      <img src="/img/logo/rally-krumlov.png" alt="Ralley Cesky Krumlov" style="max-width: 300px;">
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section section--alice" id="sec-3" data-sec="3">
      <div class="container">
        <div class="row mb-5 pb-md-3">
          <div class="col-12 col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">
            <h2 class="title fs-32 fs-sm-50 fs-md-42 fs-xl-58 fw-600 ta-center mb-3 mb-md-4">{{ cc.sec3.title[0] }} <span class="tc-blue">{{ cc.sec3.title[1] }}</span></h2>
            <p class="text tc-silver fs-14 fs-sm-20 fw-400 lh-20 lh-md-17 ta-center">{{ cc.sec3.text[0] }}</p>
          </div>
        </div>
        <div class="row mb-5">
          <div class="col-12 col-xl-10 offset-xl-1">
            <div class="row">
              <div class="col-12 col-md-6 mb-sm-4 mb-md-0" v-for="(item, i) in cc.sec3.boxes" :key="'what-we-do-' + i">
                <div class="block block--services d-flex">
                  <div class="ico ico--half ico--circle me-3" :class="'ico--circle-' + item.theme">
                    <img :src="'/img/icons/ico-' + item.key + '-' + item.theme + '.svg'" :alt="item.key">
                  </div>
                  <div class="wrapper">
                    <p class="text fs-14 fs-sm-16 fs-md-20 fw-600 mb-3">{{ item.title }}</p>
                    <p class="text tc-silver fs-14 fs-md-16 lh-20">{{ item.text }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-10 offset-1 col-sm-12 offset-sm-0">
            <p class="text tc-blue fs-12 ta-center">{{ cc.sec3.text[1] }}</p>
          </div>
        </div>
      </div>
    </section>
    <section class="section" id="sec-4" data-sec="4">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">
            <div class="block block--example">
              <div class="block block--example-child">
                <p class="text tc-yellow fs-16 fs-md-20 fw-400 ta-center mb-3 mb-md-4">{{ cc.sec4.text }}</p>
                <h2 class="title fs-16 fs-sm-18 fs-md-26 fs-lg-28 fw-600 ta-center mb-4 mb-md-5">{{ cc.sec4.title }}</h2>
                <ul class="list list--numbered-circle list--numbered-circle-gray">
                  <li v-for="(item, i) in cc.sec4.list" :key="'example-list-' + i">
                    <i>{{ i + 1 }}</i>
                    <div>
                      <h2 class="title tc-blue fs-16 fs-xl-20 fw-500 mb-1">{{ item.title }}</h2>
                      <template v-if="item.text.length === 1">
                        <p class="text tc-silver fs-14 fs-sm-16 lh-17">{{ item.text[0] }} <span class="tc-silver fw-600" v-if="'link' in item">{{ item.link.text }}</span></p>
                      </template>
                      <template v-else>
                        <p class="text tc-silver fs-14 fs-sm-16 lh-17">{{ item.text[0] }} <span class="tc-silver fw-600" v-if="'link' in item"> {{ item.link.text }}</span> {{ item.text[1] }}</p>
                      </template>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section" id="sec-5" data-sec="5">
      <div class="container">
        <div class="row">
          <div class="col-12 d-md-none mb-4">
            <h2 class="title fs-32 fs-sm-50 fw-600 ta-center mb-3">{{ cc.sec5.title }}</h2>
            <p class="text tc-silver fs-14 fs-sm-20 fw-400 lh-20 ta-center px-5 px-sm-0">{{ cc.sec5.text }}</p>
          </div>
          <div class="col-12 col-md-4 col-xl-4 offset-xl-1 mb-5 mb-md-0">
            <div class="wrapper d-flex align-items-center">
              <div class="img img--hp-sec4">
                <img src="/img/pages/home/sec4/img-1.png" alt="phones">
              </div>
            </div>
          </div>
          <div class="col-12 col-md-8 col-xl-6 offset-xl-1">
            <div class="d-none d-md-block">
              <h2 class="title fs-30 fs-sm-50 fs-md-52 fs-xl-58 fw-600 mb-3">{{ cc.sec5.title }}</h2>
              <p class="text tc-silver fs-16 fs-md-20 lh-17 mb-5 pb-md-4">{{ cc.sec5.text }}</p>
            </div>
            <ul class="list list--icons">
              <li v-for="(item, i) in cc.sec5.list" :key="'choose-list-' + i">
                <div class="ico ico--circle me-4" :class="'ico--circle-' + item.theme">
                  <img :src="'/img/icons/ico-' + item.key + '-' + item.theme + '.svg'" :alt="item.key">
                </div>
                <div>
                  <h2 class="title fs-14 fs-sm-16 fs-md-20 fw-600 mb-3">{{ item.title }}</h2>
                  <p class="text tc-silver fs-14 fs-md-16 lh-20">{{ item.text }}</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section class="section" id="sec-6" data-sec="6">
      <comp-start/>
    </section>
    <section class="section section--gray" id="sec-7" data-sec="7">
      <comp-contacts/>
    </section>
    <section class="section" id="sec-additional-2" data-sec="sec-additional-2">
      <div class="container">
        <!-- <div class="row mb-5">
          <div class="col-12 col-sm-8 offset-sm-2 col-md-12 offset-md-0">
            <a href="https://apollogames.cz/track/pineal" target="_blank" class="block block--appolo"></a>
          </div>
        </div> -->
        <div class="row">
          <div class="col-12">
            <div class="payment">
              <div class="payment__images">
                <div class="payment__images-item">
                  <img src="/img/logo/ico-visa.svg" alt="visa" title="Visa">
                </div>
                <div class="payment__images-item">
                  <img src="/img/logo/ico-mastercard.svg" alt="mastercard" title="Mastercard">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import CompStart from '@/components/Start'
import CompContacts from '@/components/Contacts'
import WidgetCrypto from '@/components/widgets/Crypto'
import WidgetCalculator from '@/components/widgets/Calculator'
import SrcExternal from '@/components/SrcExternal'
export default {
  name: 'Home',
  components: {
    CompStart, CompContacts, WidgetCrypto, WidgetCalculator, SrcExternal
  },
  computed: {
    cc () {
      return this.$t('pages.hp')
    },
    locale () {
      let res = this.$i18n.locale
      res = (res === 'en' || res === 'es') ? '/' : '/' + res + '/'
      return res
    }
  },
  metaInfo () {
    const meta = this.$t('meta.hp')
    return {
      meta: [
        { name: 'description', content: meta.description },
        { name: 'keywords', content: meta.keywords }
      ]
    }
  },
  mounted () {
    window.scrollTo(0, 0)
  }
}
</script>
