<template>
  <div class="wgt-crypto">
    <div class="row mb-5">
      <div class="col-12">
        <p class="text tc-yellow fs-16 fs-md-20">{{ cc.title }}</p>
      </div>
    </div>
    <div class="row" :class="{'mb-4 pb-md-3': i !== ($store.state.hash.length - 1)}"
         v-for="(item, name, i) in $store.state.widgetData"
         :key="'crypto-item-' + name">
      <div class="col-7 col-lg-6">
        <div class="wgt-crypto__info">
          <div class="wgt-crypto__icon">
            <img :src="'/img/icons/crypto/' + item.shortName + '.svg'" alt="">
          </div>
          <div>
            <p class="text fs-14 fs-lg-18 fw-400">{{ item.fullName }}</p>
            <p class="text tc-silver fs-12 fs-lg-14 fw-400 tt-uppercase">{{ item.shortName }}</p>
          </div>
        </div>
      </div>
      <div class="col-5 col-lg-6">
        <div class="wgt-crypto__price">
          <p class="text fs-14 fs-lg-18 fw-400">€{{ item.toEur.curr }}</p>
          <p class="text fs-12 fs-lg-14 fw-400" :class="item.status">
            <i>
              <span>
                <img src="/img/icons/ui/ico-wgt-crypto-arrow-down.svg" alt="down" />
                <img src="/img/icons/ui/ico-wgt-crypto-arrow-up.svg" alt="up" />
              </span>
            </i>
            {{ item.toEur.diff }}%
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data: () => ({
    firstLoad: true,
    tick: 5000,
    cryptoArray: null
  }),
  computed: {
    cc () {
      return this.$t('widget.crypto')
    }
  },
  mounted () {
    this.$store.commit('getCryptoData')

    this.$nextTick(function () {
      window.setInterval(() => {
        this.$store.commit('getCryptoData')
        if (this.tick === 5000) {
          this.tick = 20000
        }
      }, this.tick)
    })
    window.scrollTo(0, 0)
  }
}
</script>
